import { CONNECT_ROUTES } from "domains/connect/routes";
import { DASHBOARD_ROUTES } from "domains/dashboards/Routes";
import { INBOX_ROUTES, STANDALONE_ROUTES } from "domains/messaging/views/Home/routes";
import { gigStatusOptionIds } from "domains/schedule/containers/Filters/constants";
import { type Route, boolean, date, number, route, string, union } from "react-router-typesafe-routes/dom";
import { zod } from "react-router-typesafe-routes/zod";
import { getActiveLocationId } from "services/globalState";
import { z } from "zod";

export const MARKETPLACE_ROUTES = {
  GIG_VIEWS: route(
    "",
    {
      searchParams: {
        view: union(["list", "calendar"] as const).default("list"),

        sort: string(),
        status: zod(
          z.array(
            z.enum(
              // lil hack to make the zod enum type happy. it needs one entry guaranteed
              [gigStatusOptionIds[0], ...gigStatusOptionIds],
            ),
          ),
        ).default(gigStatusOptionIds.filter((status) => status !== "cancelled")),
        position: zod(z.array(z.number())),

        calendar_date: date().default(new Date()),
        calendar_view: union(["month", "week", "day"] as const).default("month"),
      },
    },
    {
      HOME: route("home"),
      SCHEDULE: route("schedule", {
        searchParams: {
          post_gig: boolean(),
          post_gig_date: date(),
        },
      }),
      HISTORY: route("past-gigs"),
    },
  ),
  HOME: route("home"),
  SCHEDULE: route("schedule"),
  HISTORY: route("past-gigs"),
  INBOX: INBOX_ROUTES.ROOT,
  GIG_ADD: route("gigs-add"),
  GIGS: route(
    "gigs",
    {},
    {
      APPLICANTS: route(":gigId/applicants", {
        params: { gigId: zod(z.number()).default(-1) },
        searchParams: { selection: number().defined().array() },
      }),
    },
  ),
  CONNECT: route(
    "connect",
    {},
    {
      PROS: CONNECT_ROUTES("pros"),
      GROUPS: route(
        "groups",
        {},
        {
          DETAIL: route(":groupId", {
            params: { groupId: zod(z.number()).default(-1) },
            searchParams: { offerType: zod(z.enum(["select", "existing"])) },
          }),
        },
      ),
    },
  ),
  DASHBOARDS: DASHBOARD_ROUTES.ROOT,
};

export const HIRE_ROUTES = {
  JOBS: route("jobs"),
  SCHEDULE: route(
    "schedule",
    {},
    {
      PAYMENT_DUE: route("payment-due"),
      HISTORY: route("history"),
    },
  ),
  INBOX: INBOX_ROUTES.ROOT,
  DASHBOARDS: DASHBOARD_ROUTES.ROOT,
};

export const ADMIN_ROUTES_TYPES = {
  ROOT: route(
    "admin",
    {},
    {
      PROS: route(
        "pros",
        {},
        {
          DETAIL: route(
            ":id",
            { params: { id: zod(z.number()).default(-1) } },
            {
              APPLICATION_HISTORY: route("application-history"),
              PERFORMANCE: route("performance"),
              SUSPENSIONS: route("suspensions"),
              BLOCKS: route("blocks"),
            },
          ),
        },
      ),
      REFERRAL_CODE: route("referral-code"),
      REFERRAL_PROMOTIONS: route("referral-promotions"),
      BUSINESS_COUPONS: route("business-coupons"),
      CONFIGURATION: route("configuration"),
      LOCATIONS: route("locations"),
      GIGS: route("gigs"),
      SMS_MARKETING_CAMPAIGNS: route(
        "sms-marketing",
        {},
        {
          ADD: route("add"),
          DETAIL: route(":campaignId", { params: { campaignId: zod(z.string()).defined() } }),
        },
      ),
    },
  ),
};

export const ROUTES = {
  // unauthenticated routes
  SHIFT_MESSAGES: STANDALONE_ROUTES.ROOT,

  // un-onboarded routes
  SIGN_UP: route("signup", {
    searchParams: { ref: zod(z.string()), returnTo: zod(z.string()), skipAccountLinking: zod(z.boolean()) },
  }),
  ACCEPT_ORG_INVITATION: route("organization/invitations/accept/:token", { params: { token: zod(z.string()) } }),
  ACCEPT_LOCATION_INVITATION: route("invitations/accept/:token", { params: { token: zod(z.string()) } }),
  ACCOUNT_LINKING: route("account-linking"),

  CONNECT: CONNECT_ROUTES("connect"),

  // onboarded routes
  ORGANIZATIONS: route(
    "organizations",
    {},
    {
      DETAILS: route(
        ":organizationId",
        { params: { organizationId: zod(z.string()).defined() } },
        {
          INFO: route("info", { params: { organizationId: zod(z.string()).defined() } }),
          BILLING: route("billing", {
            params: {
              organizationId: zod(z.string()).defined(),
            },
            searchParams: {
              mode: zod(z.enum(["view", "edit-custom", "add-custom"])).default("view"),
            },
          }),
          TEAM: route("teams", { params: { organizationId: zod(z.string()).defined() } }),
        },
      ),
    },
  ),
  LOCATIONS: route(
    "locations",
    { searchParams: { autoMatchOptIn: zod(z.number()), resumeWith: zod(z.number().optional()) } },
    {
      ADD: route("add", { searchParams: { returnTo: zod(z.string()) } }),
      DETAILS: route(
        ":locationId",
        { params: { locationId: zod(z.number()) } },
        {
          EDIT: route("", { params: { locationId: zod(z.number()) } }),
          USERS: route("users", { params: { locationId: zod(z.number()).defined() } }),
          STRIPE: route("stripe", {
            params: { locationId: zod(z.number()).defined() },
            searchParams: { returnTo: zod(z.string()) },
          }),
          TEAM: route("teams", { params: { locationId: zod(z.number()).defined() } }),
          INFO: route("info", { params: { locationId: zod(z.number()).defined() } }),
          BILLING: route("billing", {
            params: { locationId: zod(z.number()).defined() },
            searchParams: {
              mode: zod(z.enum(["view", "edit-custom", "add-custom"])).default("view"),
              returnTo: zod(z.string()),
            },
          }),
          ADDONS: route("add-ons", { params: { locationId: zod(z.number()).defined() } }),
          ORG_REMAP: route("org-remap", { params: { locationId: zod(z.number()).defined() } }),
          ...MARKETPLACE_ROUTES,
          ...HIRE_ROUTES,
        },
      ),
    },
  ),

  NOTIFICATION_SETTINGS: route("notification-settings"),
  SETTINGS: route("settings"),

  ADMIN: ADMIN_ROUTES_TYPES.ROOT,
};

export function buildRoute<T extends Route<any, any, any, any, any>>(
  route: T,
  params: Omit<Parameters<T["buildPath"]>[0], "locationId"> & { locationId?: number },
  searchParams?: Parameters<T["buildPath"]>[1],
) {
  const locationId = getActiveLocationId() ?? -1;
  const path = route.buildPath({ locationId, ...params }, searchParams);
  if (path.startsWith(ROUTES.LOCATIONS.DETAILS.buildPath({ locationId: -1 }))) {
    return path.split("-1")[1];
  }
  return path;
}

export const locationRoutes = [
  { exact: true, route: ROUTES.LOCATIONS.ADD },
  { exact: true, route: ROUTES.LOCATIONS.DETAILS },
  { exact: true, route: ROUTES.LOCATIONS.DETAILS.EDIT },
  { exact: false, route: ROUTES.LOCATIONS.DETAILS.STRIPE },
  { exact: false, route: ROUTES.LOCATIONS.DETAILS.USERS },
  { exact: false, route: ROUTES.LOCATIONS.DETAILS.INFO },
  { exact: false, route: ROUTES.LOCATIONS.DETAILS.TEAM },
  { exact: false, route: ROUTES.LOCATIONS.DETAILS.BILLING },
  { exact: false, route: ROUTES.LOCATIONS.DETAILS.ADDONS },
  { exact: false, route: ROUTES.ORGANIZATIONS.DETAILS },
  { exact: false, route: ROUTES.ORGANIZATIONS.DETAILS.INFO },
  { exact: false, route: ROUTES.ORGANIZATIONS.DETAILS.TEAM },
  { exact: false, route: ROUTES.ORGANIZATIONS.DETAILS.BILLING },
  { exact: false, route: ROUTES.LOCATIONS.DETAILS.ORG_REMAP },
];

/**
 * @deprecated Use `buildRoute` instead
 */
export const Routes = {
  INBOX: "/inbox",
} as const;

/**
 * @deprecated Use `buildRoute` instead
 */
export const ADMIN_ROUTES = {
  ROOT: ADMIN_ROUTES_TYPES.ROOT.path,
  PROS: ADMIN_ROUTES_TYPES.ROOT.PROS.path,
  MANAGE_PROS: ADMIN_ROUTES_TYPES.ROOT.PROS.DETAIL.path,
  MANAGE_PROS_APPLICATION_HISTORY: ADMIN_ROUTES_TYPES.ROOT.PROS.DETAIL.APPLICATION_HISTORY.path,
  MANAGE_PROS_PERFORMANCE: ADMIN_ROUTES_TYPES.ROOT.PROS.DETAIL.PERFORMANCE.path,
  MANAGE_PROS_SUSPENSIONS: ADMIN_ROUTES_TYPES.ROOT.PROS.DETAIL.SUSPENSIONS.path,
  MANAGE_PROS_BLOCKS: ADMIN_ROUTES_TYPES.ROOT.PROS.DETAIL.BLOCKS.path,
  REFERRAL_CODE: ADMIN_ROUTES_TYPES.ROOT.REFERRAL_CODE.path,
  REFERRAL_PROMOTIONS: ADMIN_ROUTES_TYPES.ROOT.REFERRAL_PROMOTIONS.path,
  BUSINESS_COUPONS: ADMIN_ROUTES_TYPES.ROOT.BUSINESS_COUPONS.path,
  CONFIGURATION: ADMIN_ROUTES_TYPES.ROOT.CONFIGURATION.path,
  LOCATIONS: ADMIN_ROUTES_TYPES.ROOT.LOCATIONS.path,
  GIGS: ADMIN_ROUTES_TYPES.ROOT.GIGS.path,
} as const;
